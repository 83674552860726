import { computed } from 'vue';
import { useStore } from 'vuex';

export function useReleaseBlock() {
    const store = useStore();

    const hasCompletedFirstPage = computed(() => store.getters['user/hasCompletedFirstPage']);

    const checkReleaseBlock = (event: MouseEvent) => {
        event.preventDefault();

        const target = event.currentTarget as HTMLAnchorElement;

        if (!hasCompletedFirstPage.value) {
            store.dispatch('user/showFirstPageBlock', true);
        } else {
            window.location.href = target.href;
        }
    };

    return {
        checkReleaseBlock,
    };
}
