<template>
    <Modal
        ref="firstPageBlockGuideRef"
        v-bind="bindTestData('introductionFlow.modal.firstPageBlockGuide')"
        variation="guide"
        @escape="hide('firstPageBlockGuideRef')"
        @enter="hide('firstPageBlockGuideRef')"
        @hidden="modalClosed"
    >
        <template #header>
            <p class="text-2xl mt-4 mb-6">
                {{ t('Create your Page first!') }}
            </p>
        </template>

        <template #body>
            <p class="mb-4">
                {{
                    t(
                        'In order to proceed to the Releases page, you need to create your Page first. Start by clicking the “New Page” button.',
                    )
                }}
            </p>
        </template>
    </Modal>
</template>

<script setup lang="ts">
    import { computed, onMounted, ref, watch } from 'vue';
    import { useStore } from 'vuex';

    import useModalAware from '@/composables/useModalAware';
    import i18n from '@/i18n';

    import Modal from '@/components/global/Modal.vue';

    import { bindTestData } from '@/tests/utilities';

    const store = useStore();

    const firstPageBlockGuideRef = ref<InstanceType<typeof Modal> | null>(null);

    const { show, hide } = useModalAware({ firstPageBlockGuideRef });

    const { t } = i18n.global;

    const shouldShowFirstPageBlockGuide = computed(() => store.getters['user/firstPageBlocked']);

    onMounted(() => {
        watch(
            () => shouldShowFirstPageBlockGuide.value,
            (value) => {
                if (value) {
                    show('firstPageBlockGuideRef');
                }
            },
            { immediate: true },
        );
    });

    const modalClosed = () => {
        store.dispatch('user/showFirstPageBlock', false);
    };
</script>
