<template>
    <header
        class="top-0 flex justify-between items-center w-full bg-gray-900 z-40 pr-6"
        style="box-shadow: 0 6px 18px #00000070"
        :class="{
            sticky: stickyHeader,
        }"
    >
        <a href="/">
            <img src="/es-pics/es_logo.svg" class="h-[58px]" />
        </a>

        <div class="flex-grow flex justify-between items-center p-2">
            <nav class="z-50 items-center mt-2 max-w-sm xl:max-w-lg">
                <div class="hidden lg:flex">
                    <template v-if="retail">
                        <a
                            v-if="pageName"
                            :href="pageUrl"
                            class="hover:text-white hover:no-underline ml-4 p-px text-4xl font-semibold tracking-tighter truncate transition-opacity duration-700"
                            :class="[showPageName ? 'opacity-100' : 'opacity-0', !pageUrl ? 'cursor-default' : '']"
                        >
                            <span>{{ pageName }}</span>
                        </a>
                    </template>

                    <template v-else>
                        <a
                            href="/es/pages"
                            class="link link-gray hover:no-underline font-semibold hidden md:block mx-8 text-sm uppercase"
                        >
                            {{ t('Pages') }}
                        </a>

                        <a
                            href="/es/releases"
                            class="link link-gray hover:no-underline font-semibold hidden md:block mr-8 text-sm uppercase"
                            @click="checkReleaseBlock"
                        >
                            {{ t('Releases') }}
                        </a>
                    </template>
                </div>
            </nav>

            <Search />

            <nav class="flex items-center">
                <CountrySelect class="hidden md:block mr-8" />

                <UserMenu v-if="authenticated" ref="userMenuRef" :user="user" class="mr-8" />

                <template v-else>
                    <a href="/login" class="link link-gray hover:no-underline mr-8">{{ t('Login') }}</a>
                </template>

                <div
                    class="relative cursor-pointer link"
                    :class="authenticated ? 'mr-8 md:mr-0' : ''"
                    @click.stop="showCart"
                >
                    <font-awesome-icon icon="fa-light fa-bag-shopping" class="text-2xl" />

                    <span
                        class="flex items-center justify-center absolute bottom-0 -right-1 h-4 w-4 pb-1 bg-white rounded-full"
                    >
                        <span class="text-sm text-black tracking-tighter pt-[3px] ml-[-1px]">
                            {{ totalQuantity }}
                        </span>
                    </span>
                </div>

                <a v-if="authenticated" href="#" class="md:hidden" @click.stop.prevent="openMenu">
                    <font-awesome-icon icon="fa-light fa-bars" class="text-2xl" />
                </a>
            </nav>

            <Cart />

            <SlideoutMenu ref="slideoutRef" :user="user" />
        </div>
    </header>

    <FirstPageBlockGuide />
</template>

<script setup lang="ts">
    import { computed, onMounted, ref } from 'vue';
    import { useStore } from 'vuex';

    import { useReleaseBlock } from '@/composables/useReleaseBlock';
    import i18n from '@/i18n';

    import CountrySelect from '@/components/global/locales/CountrySelect.vue';
    import FirstPageBlockGuide from '@/components/global/modals/FirstPageBlockGuide.vue';
    import Search from '@/components/global/search/Search.vue';
    import SlideoutMenu from '@/components/global/SlideoutMenu.vue';
    import UserMenu from '@/components/global/UserMenu.vue';
    import Cart from '@/components/retail/Cart.vue';

    const { checkReleaseBlock } = useReleaseBlock();

    const { authenticated, retail } = defineProps({
        authenticated: {
            type: Boolean,
            required: true,
        },
        retail: {
            type: Boolean,
            required: true,
        },
        isHomepage: {
            type: Boolean,
            required: false,
            default: false,
        },
        stickyHeader: {
            type: Boolean,
            required: false,
            default: true,
        },
    });

    const store = useStore();
    const { t } = i18n.global;

    const slideoutRef = ref<InstanceType<typeof SlideoutMenu> | null>(null);
    const userMenuRef = ref<InstanceType<typeof UserMenu> | null>(null);

    const user = computed(() => store.getters['user/user']);
    const totalQuantity = computed(() => store.getters['cart/totalQuantity']);
    const pageName = computed(() => store.state.navbar.pageName);
    const pageUrl = computed(() => store.state.navbar.pageUrl);
    const showPageName = computed(() => store.state.navbar.showPageName);

    onMounted(async () => {
        // Refresh the cart once every 30s
        setInterval(() => store.dispatch('cart/fetch'), 30000);
    });

    const openMenu = () => {
        slideoutRef.value?.open();
    };

    const showCart = () => {
        store.dispatch('cart/showCart');
        window.emitter.emit('close-user-modal');
    };
</script>
