import { UserApiResponse } from '@/types/api/user';
import { IntroductionFlags, User } from '@/types/user';

export const userMapper: (response: UserApiResponse) => User = (response) => {
    const flagOrder: Array<keyof IntroductionFlags> = [
        'basic_account_details',
        'verify_creator_account',
        'create_first_page',
        'edit_your_page',
        'complete_page',
        'first_page_complete',
        'create_first_release',
        'complete_your_release',
        'complete_track_metadata',
        'test_product_ordered',
        'attach_release_group',
        'release_complete',
    ];

    // Find the next introduction flag that exists in the introduction_flags object
    const nextIntroductionFlag: keyof IntroductionFlags | null = response.introduction_flags
        ? flagOrder.find((flag) => response.introduction_flags && response.introduction_flags[flag] !== true) || null
        : null;

    return {
        uuid: response.uuid,
        name: response.name,
        first_name: response.name?.split(' ')[0] || '',
        email: response.email,
        is_seller: response.is_seller,
        is_admin: response.is_admin,
        records_order_qty_limit: response.records_order_qty_limit,
        max_releases_reached: response.max_releases_reached,
        releases_limit: response.releases_limit,
        introduction_complete: response.introduction_complete,
        has_2fa: response.has_2fa,
        creator_data: response.creator_data,
        introduction_flag: nextIntroductionFlag || null,
        has_completed_first_page:
            !response.introduction_flags || response.introduction_flags?.first_page_complete || false,
        show_first_page_block: false,
    };
};
